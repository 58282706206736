import dialogAPI from "operations/dialog";

import SignInModal from "./SignInModal";

import { Steps } from "./duck/types";

interface OpenModalOptions {
  step?: Steps;
  returnFocus?: ReturnFocus | [Steps, ReturnFocus];
}

const openModal = (options?: OpenModalOptions) =>
  dialogAPI.open(
    <SignInModal
      initialStep={options?.step}
      returnFocus={options?.returnFocus}
    />,
  );

export default openModal;
