import WavingHandEmoji from "emojis/waving-hand.svg?react";

import { useDialog } from "components/dialog";

import signInModalClasses from "../../styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

const Success = () => {
  const { resolve } = useDialog();

  return (
    <div className={classes.wrapper}>
      <WavingHandEmoji
        className={classes.wavingHandEmoji}
        onAnimationEnd={() => setTimeout(() => resolve(null), 300)}
      />
      <h2 className={signInModalClasses.gradientTitle}>
        Your email has been verified
      </h2>
      <p className={signInModalClasses.description}>
        Light up your purchase experience and dance through your crypto journey!
      </p>
    </div>
  );
};

export default Success;
